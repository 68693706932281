import React, { useState } from "react";
import config from "./config";
import Results from "./Results";
import Suggestions from "./Suggestions";

//const url = `${PROXY_URL}https://od-api.oxforddictionaries.com:443/api/v2/entries/en-gb/${word.toLowerCase()}`;
// see: https://languages.oup.com/research/oed-researcher-api/

const Search = () => {
  const [word, setWord] = useState(""); // state for the search box
  const [words, setWords] = useState(); // word results from OED
  const [suggestions, setSuggestions] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const baseUrl = config.proxy_url ? `${config.proxy_url}https://oed-researcher-api.oxfordlanguages.com` : "/proxy-oed";
  if (!config.app_id || !config.app_key) {
    setErrors([...errors, "Environment vars 'app_id' and / or 'app_key' not found"]);
  }

  const getSuggestions = async (word) => {
    try {
      const response = await fetch(`https://api.datamuse.com/words?sp=${word}`);
      const json = await response.json();
      console.log(`Results returned from datamuse API for '${word}':`);
      console.log(json);
      setSuggestions(json);
    } catch (error) {
      setErrors([...errors, error]);
    }
  };

  const searchWord = async (word) => {
    const url = `${baseUrl}/oed/api/v0.2/words/?lemma=${word.toLowerCase()}&obsolete=false`;
    const headers = { app_id: config.app_id, app_key: config.app_key };
    try {
      const response = await fetch(url, { headers }).catch((error) => {
        setErrors([...errors, error]);
      });
      if (!response || !response.ok) return;
      const json = await response.json();
      console.log(`Results returned from oed API for '${word}':`);
      console.log(json);
      setWords(json);
    } catch (error) {
      setErrors([...errors, error]);
    }
  };

  const doSearchs = async (word) => {
    setLoading(true);
    await searchWord(word); // TODO call in parallel
    await getSuggestions(word);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!word) return; // TODO prevent submission, add message etc
    await doSearchs(word);
  };

  const handleSelectSuggestion = async (suggestion) => {
    setWord(suggestion);
    await doSearchs(suggestion);
  };

  // TODO add error boundary
  if (errors.length > 0)
    return (
      <section className="section">
        <div className="container has-text-danger">
          {errors.map((e, i) => (
            <div key={i}>
              <p>name: {e.name}</p>
              <p>message: {e.message}</p>
              <p>description: {e.description}</p>
              <p>fileName: {e.fileName}</p>
              <p>lineNumber: {e.lineNumber}</p>
              <p>columnNumber: {e.columnNumber}</p>
              <p>stack: {e.stack}</p>
              <p>toString: {e.toString()}</p>
            </div>
          ))}
        </div>
      </section>
    );

  return (
    <>
      <section className="section">
        <div className="container mb-6 is-max-desktop">
          <form onSubmit={handleSubmit}>
            <div className="field is-grouped">
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  placeholder="aardvark"
                  value={word}
                  onChange={(e) => setWord(e.target.value)}
                  onBlur={(e) => setWord(e.target.value)}
                ></input>
              </div>
              <div className="control">
                <button
                  type="submit"
                  className={`button is-info ${loading ? "is-loading" : ""}`}
                  style={{ width: "100px" }}
                >
                  {loading ? "&nbsp;" : "Search"}
                </button>
              </div>
            </div>
          </form>
        </div>
        <Results loading={loading} words={words} />
        <Suggestions loading={loading} suggestions={suggestions} selectSuggestion={handleSelectSuggestion} />
      </section>
    </>
  );
};

export default Search;
