import React, { useContext } from "react";
import { sanity } from "./sanity";
import UserContext from "./UserContext";

// https://www.sanity.io/docs/js-client

const Result = ({ lemma, words }) => {
  const [user] = useContext(UserContext);

  const handleClick = (word) => {
    const doc = {
      _type: "word",
      lemma: word.lemma,
      url: word.oed_reference,
      source: "something hardcoded", // TODO get this
      context: "context",
      user: user.id, // TODO is this correct?
    };

    sanity.create(doc).then((res) => {
      console.log(`Word was saved, document ID is ${res._id}`);
    });
  };

  return (
    <div className="mb-3 card" key={lemma}>
      <div className="level card-header is-mobile mb-0 px-3 py-1">
        <div className="level-left">
          <h2 className="is-size-4">{lemma}</h2>
        </div>
        <div className="level-right">
          <button className="button is-small ml-6 is-pulled-right is-primary" onClick={() => handleClick(words[0])}>
            Save to history
          </button>
          {/* <span className="tag is-primary">Saved!</span> */}
          {/* <span className="tag is-primary">Saved on xx/xx/xxxx</span> */}
        </div>
      </div>
      {words.map((word) => (
        <article key={word.id} className="m-3" style={{ border: "solid 1px white" }}>
          <h4 className="subtitle mb-0">
            <a href={word.oed_url} target="_blank" rel="noreferrer">
              {word.oed_reference} (band: {word.band}, lemma: {word.lemma})
            </a>
          </h4>
          <p>{word.definition}</p>
        </article>
      ))}
    </div>
  );
};

export default Result;
