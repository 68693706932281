import React from "react";
import styled from "styled-components";

const SuggestionsStyles = styled.div`
  ol {
    list-style-type: none;
  }
  .column {
    padding-top: 0;
    padding-bottom: 0;
  }
  li:hover {
    background-color: hsl(206, 70%, 96%);
    cursor: pointer;
  }
  .progress-container:before {
    content: attr(data-text);
    position: absolute;
    left: 25;
    right: 0;
    top: 0;
    line-height: 1em;
  }
  .progress-container {
    position: relative;
    color: white;
  }
`;

const Suggestions = ({ loading, suggestions, selectSuggestion }) => {
  if (!suggestions) return <></>;
  if (loading) return <></>;
  const getMaxSuggestionsScores = suggestions.reduce((acc, cur) => {
    return cur.score > acc ? cur.score : acc;
  }, 0);
  return (
    <SuggestionsStyles className="container is-max-desktop">
      <h2 className="is-size-5 mb-3 has-text-info">Did you mean?</h2>
      <ol>
        {suggestions.map((suggestion) => (
          <li
            key={suggestion.word}
            className="columns is-vcentered is-mobile"
            onClick={() => selectSuggestion(suggestion.word)}
          >
            <div className="column has-text-right">{suggestion.word}</div>
            <div className="column is-8 progress-container" data-text={suggestion.score}>
              <progress className="progress is-info" value={suggestion.score} max={getMaxSuggestionsScores}>
                {suggestion.score}
              </progress>
            </div>
            <div className="column">
              <button className="button is-ghost">Search</button>
            </div>
          </li>
        ))}
      </ol>
    </SuggestionsStyles>
  );
};

export default Suggestions;
