import React, { useContext } from "react";
import { useQuery } from "react-query";
import { sanity } from "./sanity";
import UserContext from "./UserContext";

const WordHistory = () => {
  const [user] = useContext(UserContext);
  const query = `*[ _type == 'word' && user->username == '${user.username}'] { _id, _createdAt, _updatedAt, lemma, context, url, source, user-> }`;
  const { data } = useQuery("words", () => sanity.fetch(query));

  if (!user || !data)
    return (
      <section className="section">
        <div className="container is-max-desktop">
          <h1>Loading…</h1>
        </div>
      </section>
    );

  console.log(`Results returned from sanity client:`);
  console.log(data);
  return (
    <section className="section">
      <div className="container is-max-desktop">
        <h1 className="title">Word history</h1>
        <table className="table">
          <thead>
            <tr>
              <th>Word</th>
              <th>Context</th>
              <th>Source</th>
              <th>Date added</th>
            </tr>
          </thead>
          <tbody>
            {data.map((w) => (
              <tr key={w._id}>
                <td>
                  <a href={w.url}>{w.lemma}</a>
                </td>
                <td>{w.context}</td>
                <td>{w.source._ref}</td>
                <td>{w._updatedAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default WordHistory;
