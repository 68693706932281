import React from "react";
import Result from "./Result";

const Results = ({ loading, words }) => {
  if (!words) return <></>;
  if (loading) return <></>;

  words.data.sort((a, b) => {
    return a.band > b.band;
  });

  const lemmas = words.data.reduce((acc, cur) => {
    if (acc.includes(cur.lemma)) return acc;
    else {
      acc.push(cur.lemma);
      return acc;
    }
  }, []);

  return (
    <>
      <div className="container mb-6">
        {/* test this with "wither" or "v" */}
        {lemmas.map((lemma) => (
          <Result key={lemma} lemma={lemma} words={words.data.filter((word) => word.lemma === lemma)} />
        ))}
      </div>
      <div className="container mb-6 is-max-desktop">
        <div className="field">
          <label className="label" htmlFor="context">
            Context
          </label>
          <div className="control">
            <textarea
              id="context"
              name="context"
              className="textarea"
              placeholder="Original text containing word etc"
              rows="2"
            ></textarea>
          </div>
        </div>
      </div>
    </>
  );
};
export default Results;
