import React, { useState } from "react";
import { render } from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Header from "./Header";
import Search from "./Search";
import Details from "./Details";
import WordHistory from "./WordHistory";
import Login from "./Login";
import Logout from "./Logout";
import UserContext from "./UserContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const App = () => {
  const userHook = useState({});
  return (
    <React.StrictMode>
      <UserContext.Provider value={userHook}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Header />
            <Route path="/" exact={true}>
              <Search />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/logout">
              <Logout />
            </Route>
            <Route path="/history">
              <WordHistory />
            </Route>
            <Route path="/details/:word">
              <Details />
            </Route>
          </BrowserRouter>
        </QueryClientProvider>
      </UserContext.Provider>
    </React.StrictMode>
  );
};

render(<App />, document.getElementById("root"));
