import React, { useContext } from "react";
import { useQuery } from "react-query";
import { sanity } from "./sanity";
import UserContext from "./UserContext";
import { useHistory } from "react-router-dom";

const query = `*[ _type == 'user'] { _id, username, firstname}`;

const Login = () => {
  const [user, setUser] = useContext(UserContext);
  const { data } = useQuery("users", () => sanity.fetch(query));
  const history = useHistory();

  const handleLogin = (e) => {
    e.preventDefault();
    const select = document.querySelector("#users");
    setUser(data.find((x) => x.username == select.value));
    history.push("/");
  };

  const handleCancel = (e) => {
    e.preventDefault();
    history.push("/");
  };

  const handleLogout = (e) => {
    e.preventDefault();
    history.push("/logout");
  };

  if (user.username)
    return (
      <section className="section">
        <div className="container is-max-desktop">
          <h1 className="title">Login</h1>
          <h2 className="subtitle">Already logged in as {user.username}</h2>
          <button className="button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </section>
    );

  if (!data)
    return (
      <section className="section">
        <div className="container is-max-desktop">
          <h1>Loading ...</h1>
        </div>
      </section>
    );

  return (
    <section className="section">
      <div className="container is-max-desktop">
        <h1 className="title">Login</h1>

        <div className="field">
          <label className="label" htmlFor="users">
            Select user
          </label>
          <div className="control">
            <div className="select">
              <select name="users" id="users">
                <option></option>
                {data.map((user) => (
                  <option key={user._id} value={user.username}>
                    {user.username}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-link" onClick={handleLogin}>
              Login
            </button>
          </div>
          <div className="control">
            <button className="button is-link is-light" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
