import SanityClient from "@sanity/client";

export const sanity = SanityClient({
  projectId: "vc122e5r",
  dataset: "production",
  apiVersion: "2021-05-30",
  // this option enables faster responses
  // but can return stale data at times.
  // recommended for client-side queries
  useCdn: true,
  watch: true, // in dev automatically detects changes from sanity
  token: process.env.SANITY_TOKEN,
});
