import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "./UserContext";

const Header = () => {
  const [user] = useContext(UserContext);
  const handleBurgerClick = (e) => {
    const target = e.target.dataset.target;
    const targetElement = document.getElementById(target);

    e.target.classList.toggle("is-active");
    if (targetElement) targetElement.classList.toggle("is-active");
  };

  return (
    // <div className="container has-text-centered mt-5">
    //   <h1 className="title">
    //     <Link to="/">Words</Link>
    //   </h1>
    //   <p className="subtitle">Search the Oxford English Dictionary</p>
    // </div>
    <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          Words
        </Link>
        <a
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={handleBurgerClick}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          <Link to="/" className="navbar-item">
            Search
          </Link>
          <Link to="/history" className="navbar-item">
            Word history
          </Link>
          <Link to="/logs" className="navbar-item">
            Activity logs
          </Link>
          <Link to="/settings" className="navbar-item">
            Settings
          </Link>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">More</a>

            <div className="navbar-dropdown">
              <Link to="/topics" className="navbar-item">
                Topics
              </Link>
              <a className="navbar-item">Regions</a>
              <a className="navbar-item">Usage</a>
              <a className="navbar-item">Languages</a>
              <a className="navbar-item">Parts of speech</a>
              <a className="navbar-item">Etymology</a>
              <hr className="navbar-divider" />
              <a className="navbar-item">About</a>
            </div>
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            {user?.username ? (
              <div>
                Logged in as <span className="is-info">{user.username}</span>
              </div>
            ) : (
              <div className="buttons">
                <Link to="/login" className="button is-link">
                  Log in
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
